import { useMemo } from "react"

import { MultiPillSelectResult } from "src/components/Paradise/MultiPillSelect"
import { ParadiseSettingMultiPillSelect } from "src/components/Paradise/ParadiseSettings/ParadiseSettingMultiPillSelect"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePutHomeTags } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { isSuperAdmin } from "src/data/user/logic/accessLogic"
import { MBadge } from "src/ui/MBadge/MBadge"

export function ParadiseHomeOverviewTags({
  currentTags,
  homeId,
}: {
  currentTags: string[]
  homeId: string
}) {
  const putHomeTags = usePutHomeTags()

  const loggedInUser = useGetUser()

  async function handleSave(
    selectedTags: MultiPillSelectResult[]
  ): TSettingContainerOnSaveReturnType {
    try {
      await putHomeTags.mutateAsync({
        homeId,
        body: {
          tags: selectedTags.map((t) => t.id),
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  const displayValue = useMemo(() => {
    if (currentTags.length > 0) {
      return (
        <PillsWrapper>
          {currentTags.map((tag) => (
            <MBadge key={tag} size="small" color="neutral">
              {tag}
            </MBadge>
          ))}
        </PillsWrapper>
      )
    }

    return "-"
  }, [currentTags])

  return (
    <ParadiseSettingMultiPillSelect
      title="Tags"
      displayValue={displayValue}
      initialSelected={convertToMultiPillResult(currentTags)}
      options={convertToMultiPillResult(currentTags)}
      onSave={handleSave}
      disabled={!isSuperAdmin(loggedInUser.roles)}
    />
  )
}

function convertToMultiPillResult(arr: string[]): MultiPillSelectResult[] {
  return arr.map((t) => ({ id: t, name: t }))
}
