import { useState } from "react"

import SearchFilter from "src/components/Filter/SearchFilter"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import { FilterWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchParadiseDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { useSearchParams } from "src/router/useSearchParams"

const LIMIT = 10

export function ParadiseHomeDevices({ homeId }: { homeId: string }) {
  const [offset, setOffset] = useState(0)
  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "devices_description",
        type: "string",
      },
    ],
  })

  const fetchedHomeDevices = useFetchParadiseDevices({
    filter: {
      home_id: homeId,
      limit: LIMIT,
      offset,
      description: filter.devices_description || undefined,
      active: true,
    },
    options: {
      keepPreviousData: true,
    },
  })

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />
      <FilterWrapper>
        <SearchFilter
          initialValue={filter.devices_description ?? undefined}
          label="Serach by description"
          onChange={(v) => setFilter("devices_description", v)}
          fullWidth
        />
      </FilterWrapper>
      <ParadiseDevicesTable
        devices={fetchedHomeDevices.data?.devices ?? []}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchedHomeDevices.data?.total_count}
        error={{
          hasError: fetchedHomeDevices.isError,
          title: fetchedHomeDevices.error?.message,
          description: fetchedHomeDevices.error?.response?.data,
        }}
      />
    </div>
  )
}
